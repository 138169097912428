<template>
  <div>
    <el-row>
      <el-col :span="16" :offset="4">
        <el-card class="box-card">
          <div>
            <el-carousel :interval="4000" type="card" height="300px">
              <el-carousel-item v-for="item in bgImgs" :key="item.id">
                <img :src="item.url" alt="" style="height: 100%;width: 100%;">
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-card>

        <el-card class="box-card" style="margin-top: 10px;">
          <div slot="header" class="clearfix">
            <span>文章</span>
          </div>
          <div>
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item v-for="(item, index3) in articleData" :key="index3" :title="item.title" :name="index3">
                <div v-html="item.content"></div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-card>

        <el-card class="box-card" style="margin-top: 10px;">
          <div slot="header" class="clearfix">
            <span>更新日志</span>
          </div>
          <div >
            <el-timeline>
              <el-timeline-item v-for="(item,index) in data" :key="index" :timestamp="item.createTimeFormat" placement="top">
                <el-card>
                  <h4>{{ item.title }}</h4>
                  <p v-html="item.content.replace(/\n/g, '<br/>')"></p>
                  <p>{{ item.author }} 提交于 {{ item.createTimeFormat }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import homeApi from "@/api/home"
export default {
  name: "Home",
  data() {
    return {
      bgImgs: [],
      activeNames: '0',
      data: [],
      articleData: []
    }
  },
  created() {
    this.getRecordList()
    this.getBannerList()
    this.getArticleList()
  },
  methods: {
    handleChange(obj) {
      console.log(obj);
    },
    /**
     * 查询轮播图
     */
    getBannerList() {
      homeApi.getBannerList().then(res => {
        this.bgImgs = res.data
      })
    },
    /**
     * 查询创作历史
     */
    getRecordList() {
      homeApi.getRecordList().then(res => {
        this.data = res.data
      })
    },
    /**
     * 查询文章
     */
    getArticleList() {
      homeApi.getArticleList().then(res => {
        this.articleData = res.data
      })
    }
  }
}
</script>

<style >
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}

table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}

pre code {
  display: block;
}

/* ul ol 样式 */
ul,
ol {
  margin: 10px 0 10px 20px;
}</style>

